/*

Copyright 2017-present Palantir Technologies, Inc. All rights reserved.
Licensed under the Apache License, Version 2.0.

*/
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-multi-select {
  min-width: 150px;
}

.bp4-multi-select-popover .bp4-menu {
  max-height: 300px;
  max-width: 400px;
  overflow: auto;
}
.bp4-multi-select-popover.bp4-popover2-match-target-width {
  width: 100%;
}
.bp4-multi-select-popover.bp4-popover2-match-target-width .bp4-menu {
  max-width: none;
  min-width: 0;
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp4-omnibar {
  filter: blur(0);
  opacity: 1;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 4px 8px rgba(17, 20, 24, 0.2), 0 18px 46px 6px rgba(17, 20, 24, 0.2);
  left: calc(50% - 250px);
  top: 20vh;
  width: 500px;
  z-index: 21;
}
.bp4-omnibar.bp4-overlay-enter, .bp4-omnibar.bp4-overlay-appear {
  filter: blur(20px);
  opacity: 0;
}
.bp4-omnibar.bp4-overlay-enter-active, .bp4-omnibar.bp4-overlay-appear-active {
  filter: blur(0);
  opacity: 1;
  transition-delay: 0;
  transition-duration: 200ms;
  transition-property: filter, opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-omnibar.bp4-overlay-exit {
  filter: blur(0);
  opacity: 1;
}
.bp4-omnibar.bp4-overlay-exit-active {
  filter: blur(20px);
  opacity: 0;
  transition-delay: 0;
  transition-duration: 200ms;
  transition-property: filter, opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-omnibar .bp4-input {
  background-color: transparent;
  border-radius: 0;
}
.bp4-omnibar .bp4-input, .bp4-omnibar .bp4-input:focus {
  box-shadow: none;
}
.bp4-omnibar .bp4-menu {
  background-color: transparent;
  border-radius: 0;
  box-shadow: inset 0 1px 0 rgba(17, 20, 24, 0.15);
  max-height: calc(60vh - 40px);
  overflow: auto;
}
.bp4-omnibar .bp4-menu:empty {
  display: none;
}
.bp4-dark .bp4-omnibar, .bp4-omnibar.bp4-dark {
  background-color: #383e47;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 4px 8px rgba(17, 20, 24, 0.4), 0 18px 46px 6px rgba(17, 20, 24, 0.4);
}

.bp4-omnibar-overlay .bp4-overlay-backdrop {
  background-color: rgba(17, 20, 24, 0.2);
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-select-popover .bp4-popover-content,
.bp4-select-popover .bp4-popover2-content {
  padding: 5px;
}
.bp4-select-popover .bp4-input-group {
  margin-bottom: 0;
}
.bp4-select-popover .bp4-menu {
  max-height: 300px;
  max-width: 400px;
  overflow: auto;
  padding: 0;
}
.bp4-select-popover .bp4-menu:not(:first-child) {
  padding-top: 5px;
}
.bp4-select-popover.bp4-popover2-match-target-width, .bp4-select-popover.bp4-select-match-target-width {
  width: 100%;
}
.bp4-select-popover.bp4-popover2-match-target-width .bp4-menu, .bp4-select-popover.bp4-select-match-target-width .bp4-menu {
  max-width: none;
  min-width: 0;
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-suggest-popover .bp4-menu {
  max-height: 300px;
  max-width: 400px;
  overflow: auto;
}
.bp4-suggest-popover.bp4-popover2-match-target-width {
  width: 100%;
}
.bp4-suggest-popover.bp4-popover2-match-target-width .bp4-menu {
  max-width: none;
  min-width: 0;
}